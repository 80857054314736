.App {
  text-align: center;
  height: 100vh;
  /*background-color: black;*/
  max-height: 100vh;
  background-image: url("assets/space4k.jpg");
}

@media only screen and (max-width: 1920px) {
  .App {
    background-image: url("assets/space1920.png");
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

