.spaceDot{
  position: fixed;
  border: 1px solid white;
  border-radius: 25px;
  max-height: 100vh;
  z-index: 0;
}

.shiningSpaceDot { animation: color-pop 4s infinite; }

@keyframes color-pop {
  from {  background-color : yellow; border-color: yellow  }
  to   { background-color : white ; border-color: white  }
}
