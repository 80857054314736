.planets-container{
  height: 100vh;
  .planet-felix{
    width: 150px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform:  translate(-50%, -50%)
  }
  .planet-mooncake{
    width: 150px;
    position: fixed;
    left: 45%;
    top: 45%;
    cursor: pointer;
    -webkit-animation: mooncake-spin 7s linear infinite;
    -moz-animation: mooncake-spin 7s linear infinite;
    -o-animation: mooncake-spin 7s linear infinite;
    animation: mooncake-spin 7s linear infinite;
  }
}

@keyframes mooncake-spin {
	from { 	-webkit-transform: rotate(0deg) translate(-250%) rotate(0deg); }
	to   {  -webkit-transform: rotate(360deg) translate(-250%) rotate(-360deg); }
}

@-moz-keyframes mooncake-spin {
  from { 	-webkit-transform: rotate(0deg) translateX(-250%) rotate(0deg); }
  to   {  -webkit-transform: rotate(360deg) translateX(-250%) rotate(-360deg); }
}

@-o-keyframes mooncake-spin {
  from { 	-webkit-transform: rotate(0deg) translateX(-250%) rotate(0deg); }
  to   {  -webkit-transform: rotate(360deg) translateX(-250%) rotate(-360deg); }
}

@keyframes mooncake-spin {
  from { 	-webkit-transform: rotate(0deg) translateX(-250%) rotate(0deg); }
  to   {  -webkit-transform: rotate(360deg) translateX(-250%) rotate(-360deg); }
}
